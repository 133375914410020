var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.title,
        visible: _vm.dialogFormVisible,
        width: "400px"
      },
      on: {
        "update:visible": function($event) {
          _vm.dialogFormVisible = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.loadingExPort, type: "primary" },
                  on: { click: _vm.sync }
                },
                [_vm._v(" 确 定 ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "el-form",
        {
          ref: "form",
          attrs: { "label-width": "120px", model: _vm.form, rules: _vm.rules }
        },
        [
          _c(
            "vab-query-form",
            [
              _c(
                "vab-query-form-top-panel",
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: { label: "", "label-width": "60px", prop: "jobNo" }
                    },
                    [
                      _c("el-input", {
                        staticStyle: { width: "250px" },
                        attrs: {
                          placeholder: "运单号 / 柜号",
                          rows: 10,
                          type: "textarea"
                        },
                        model: {
                          value: _vm.form.jobNo,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "jobNo", $$v)
                          },
                          expression: "form.jobNo"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }