var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "报关申报信息推荐", visible: _vm.open, width: "1200px" },
      on: {
        "update:visible": function($event) {
          _vm.open = $event
        },
        close: _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
              _c(
                "el-button",
                {
                  attrs: { loading: _vm.saveLoad, type: "primary" },
                  on: { click: _vm.submit }
                },
                [_vm._v(" 确认选择 ")]
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "searchContainer" },
        [
          _vm.searchCustoms
            ? _c(
                "el-button",
                {
                  attrs: { size: "mini", type: "primary" },
                  on: { click: _vm.search2 }
                },
                [_vm._v(" 查询海关编码网站 ")]
              )
            : _vm._e(),
          _c(
            "el-button",
            {
              attrs: { size: "mini", type: "primary" },
              on: { click: _vm.preview }
            },
            [_vm._v(" 产品图片预览 ")]
          ),
          _c("div", { staticClass: "link" }, [
            _c("div", [_vm._v("产品链接：")]),
            _c(
              "a",
              {
                attrs: { href: _vm.searchInfo.transactionUrl, target: "_blank" }
              },
              [_vm._v(" " + _vm._s(_vm.searchInfo.transactionUrl) + " ")]
            )
          ])
        ],
        1
      ),
      _c(
        "vxe-table",
        {
          ref: "multipleTable",
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            height: "400",
            "row-style": _vm.rowStyle,
            "scroll-x": {
              enabled: true
              // gt: 10,
              // oSize: 10,
            },
            "tooltip-effect": "dark"
          },
          on: { "radio-change": _vm.handleSelectionChange }
        },
        [
          _c("vxe-column", {
            attrs: {
              align: "center",
              fixed: "left",
              type: "radio",
              width: "40"
            }
          }),
          _c("vxe-column", {
            attrs: { field: "hsCode", title: "中国海关编码", width: "110" }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "cusProductCnName",
              title: "客户中文品名",
              width: "160"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "materialCn",
              title: "中文材质",
              width: "100"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "reportCnName",
              title: "申报中文品名",
              width: "120"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "items",
              title: "商品名称",
              width: "200"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "description",
              title: "商品描述",
              width: "200"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "chapter",
              title: "章节",
              width: "200"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "category",
              title: "大类",
              width: "200"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "regulatoryCondition",
              title: "监管条件",
              width: "120"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "inspection",
              title: "检验检疫",
              width: "120"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "frequency",
              fixed: "right",
              title: "历史使用频次",
              width: "120"
            }
          }),
          _c("vxe-column", {
            attrs: {
              align: "center",
              field: "recommendSource",
              fixed: "right",
              title: "推荐来源",
              width: "120"
            }
          })
        ],
        1
      ),
      _c("Preview", { ref: "preview" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }