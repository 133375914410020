<template>
  <el-dialog
    title="报关申报信息推荐"
    :visible.sync="open"
    width="1200px"
    @close="close"
  >
    <div class="searchContainer">
      <el-button
        v-if="searchCustoms"
        size="mini"
        type="primary"
        @click="search2"
      >
        查询海关编码网站
      </el-button>
      <el-button size="mini" type="primary" @click="preview">
        产品图片预览
      </el-button>
      <div class="link">
        <div>产品链接：</div>
        <a :href="searchInfo.transactionUrl" target="_blank">
          {{ searchInfo.transactionUrl }}
        </a>
      </div>
    </div>
    <vxe-table
      ref="multipleTable"
      :data="tableData"
      height="400"
      :row-style="rowStyle"
      :scroll-x="{
        enabled: true,
        // gt: 10,
        // oSize: 10,
      }"
      style="width: 100%"
      tooltip-effect="dark"
      @radio-change="handleSelectionChange"
    >
      <vxe-column align="center" fixed="left" type="radio" width="40" />
      <vxe-column field="hsCode" title="中国海关编码" width="110" />
      <vxe-column
        align="center"
        field="cusProductCnName"
        title="客户中文品名"
        width="160"
      />
      <vxe-column
        align="center"
        field="materialCn"
        title="中文材质"
        width="100"
      />
      <vxe-column
        align="center"
        field="reportCnName"
        title="申报中文品名"
        width="120"
      />
      <vxe-column align="center" field="items" title="商品名称" width="200" />
      <vxe-column
        align="center"
        field="description"
        title="商品描述"
        width="200"
      />
      <vxe-column align="center" field="chapter" title="章节" width="200" />
      <vxe-column align="center" field="category" title="大类" width="200" />
      <vxe-column
        align="center"
        field="regulatoryCondition"
        title="监管条件"
        width="120"
      />
      <vxe-column
        align="center"
        field="inspection"
        title="检验检疫"
        width="120"
      />
      <vxe-column
        align="center"
        field="frequency"
        fixed="right"
        title="历史使用频次"
        width="120"
      />
      <vxe-column
        align="center"
        field="recommendSource"
        fixed="right"
        title="推荐来源"
        width="120"
      />
    </vxe-table>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button :loading="saveLoad" type="primary" @click="submit">
        确认选择
      </el-button>
    </template>
    <Preview ref="preview" />
  </el-dialog>
</template>

<script>
  import {
    clearanceListQueryHscApi,
    saveSelectHscApi,
    searchListApi,
  } from '@/api/cmsApi'
  import Preview from '@/vab/components/Preview'
  export default {
    components: {
      Preview,
    },
    props: {
      open: {
        type: Boolean,
        default: false,
      },
      searchInfo: {
        type: Object,
        default: new Object(),
      },
    },
    data() {
      return {
        tableData: [],
        saveLoad: false,
        searchCustoms: false,
        selectRow: {},
      }
    },
    watch: {
      open(value) {
        if (value) {
          this.search({
            id: this.searchInfo.id,
            reld: this.searchInfo.reId,
          })
        }
      },
    },
    methods: {
      rowStyle({ row }) {
        if (row.recommendSource == '其他客户历史数据') {
          return {
            backgroundColor: 'rgb(225 232 254)',
          }
        }
        if (row.recommendSource == '同客户历史数据') {
          return {
            backgroundColor: 'rgb(254 247 220)',
          }
        }
        if (row.recommendSource == '海关编码网站') {
          return {
            backgroundColor: 'rgb(212 254 205)',
          }
        }

        return {
          backgroundColor: 'rgb(254 218 220)',
        }
      },
      handleSelectionChange({ newValue }) {
        this.selectRow = newValue
      },
      preview() {
        this.$refs['preview'].showEdit(this.searchInfo, false)
      },
      async search(info) {
        const result = await searchListApi(info.id)
        this.searchCustoms = result.data.length === 0
        this.tableData = result.data
        let reld = info.reld
        if (reld) {
          const selectRow = result.data.find((item) => item.id === reld)
          this.$refs.multipleTable.setRadioRow(selectRow)
          this.selectRow = selectRow
        }
      },
      //查询海关编码
      async search2() {
        const result = await clearanceListQueryHscApi({
          custProductCnName: this.searchInfo.cusProductCnName,
          materialCn: this.searchInfo.materialCn,
        })
        this.tableData = result.data
      },
      async submit() {
        const requestData = {
          clearanceListId: this.searchInfo.id,
          sortNum: this.selectRow.sortNum,
          recommendList: this.tableData,
        }
        await saveSelectHscApi(requestData)
        this.$emit('save')
        this.close()
      },
      close() {
        this.$emit('update:open', false)
        this.$emit('refresh', false)
      },
    },
  }
</script>
<style lang="scss" scoped>
  .searchContainer {
    display: flex;
    text-align: left;
    margin-bottom: 15px;
  }

  .link {
    display: flex;
    align-items: center;
    margin-left: 10px;
    white-space: nowrap;
    a {
      display: inline-block;
      width: 900px;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
      white-space: nowrap;
      text-decoration: underline;
    }
  }
</style>
